<template>
	<el-dialog :visible.sync="showModal" class="generateOrderDialog" title="Genereaza comanda">
		<el-form ref="orderDemoForm" :model="form">

            <el-form-item v-if="showSuccess">
                <el-alert :title="'Comanda cu id-ul ' + order_id + ' a fost generata cu succes!'" type="success" :center="true" :closable="false" show-icon></el-alert>
            </el-form-item>

			<el-form-item label="Produse" prop="products">
				<el-select v-model="form.products" filterable multiple remote :remote-method="getProducts" placeholder="-- Generare automata --" style="width: 100%;">
					<el-option v-for="item in products" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</el-form-item>

            <el-form-item label="Metoda de plata" prop="payment_method">
				<el-select v-model="form.payment_method" filterable placeholder="-- Generare automata --" style="width: 100%;">
                    <el-option value="">-- Generare automata --</el-option>
					<el-option v-for="(element, index) in payment_methods" :key="index" :label="element" :value="element"></el-option>
				</el-select>
			</el-form-item>

            <el-form-item label="Judet" prop="county">
				<el-select v-model="form.county" @change="getCities" filterable placeholder="-- Generare automata --" style="width: 100%;">
                    <el-option value="">-- Generare automata --</el-option>
					<el-option v-for="(element, index) in counties" :key="index" :label="element" :value="element"></el-option>
				</el-select>
			</el-form-item>

            <el-form-item label="Oras" prop="city">
				<el-select v-model="form.city" filterable placeholder="-- Generare automata --" no-data-text="Selecteaza judetul pentru a incarca lista de orase" style="width: 100%;">
                    <el-option value="">-- Generare automata --</el-option>
					<el-option v-for="(element, index) in cities" :key="index" :label="element" :value="element"></el-option>
				</el-select>
			</el-form-item>

            <Box :type="'info'" :active="true">
                <div slot="box-body">
                    <ul class="rawUl">
                        <li><strong>ATENTIE!</strong> Comenzile vor aparea in lista de comenzi in maxim 20 de minute.</li>
                        <li>Campurile nu sunt obligatorii. Daca un camp ramane neselectat, acesta se genereaza automat.</li>
                    </ul>
                </div>
            </Box>

            <div class="center">
                <el-button @click="showModal = false">Anuleaza</el-button>
                <el-button type="primary" @click="generateOrder()">Genereaza</el-button>
            </div>
		</el-form>
	</el-dialog>
</template>

<script>
import { getCounties, getCities } from '@/api/cont';
import { getProducts } from '@/api/produse';
import { generateDemoOrder } from '@/api/vanzari';

const Box = () => import('vue-info-box-element');

export default {
    props: ['payment_methods', 'show_modal'],
	data() {
		return {
            showSuccess: false,
            order_id: 0,
            products: [],
            counties: [],
            cities: [],
			form: {
                products: [],
                payment_method: '',
                county: '',
                city: ''
            },

		};
    },
    components: {
        Box
    },
    computed: {
        showModal: {
            get() {
                return this.show_modal;
            },
            set(value) {
                this.$emit('close_modal');
            }
        }
    },
	methods: {
        getProducts(search) {
            getProducts({
                start: 0,
                limit: 25,
                filters: {
                    keyword: search,
                    status: ['1']
                }
            }).then((response) => {
                this.products = [];

                const responseProducts = response.message.data;
                if (responseProducts && response_products.length > 0) {
                    response_products.forEach((product) => {
                        this.products.push({
                            label: product.products_name + ' (' + product.products_model + ')',
                            value: product.products_model
                        });
                    });
                }
            }).catch((e) => {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'getProducts',
                        params: {
                            filters: {
                                keyword: search
                            }
                        }
                    }
                });
            });
        },
        getCities(county) {
            if (county !== '') {
                getCities({
                    county: this.form.county
                }).then((response) => {
                    if (response.message.length > 0) {
                        this.cities = response.message;
                    }
                });
            } else {
                this.cities = [];
                this.form.city = '';
            }
        },
		generateOrder() {
            generateDemoOrder({
                products: this.form.products,
                payment_method: this.form.payment_method,
                county: this.form.county,
                city: this.form.city
            }).then((response) => {
                if (response.message.success) {
                    this.showSuccess = true;
                    this.order_id = response.message.order_id;
                    this.$refs['orderDemoForm'].resetFields();
                }
            }).catch((e) => {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'generateDemoOrder',
                        params: {
                            products: this.form.products,
                            payment_method: this.form.payment_method,
                            county: this.form.county,
                            city: this.form.city
                        }
                    }
                });
            });
		}
    },
    mounted() {
        // Load products
        this.getProducts();

        getCounties().then((response) => {
            if (response.message.length > 0) {
                this.counties = response.message;
            }
        });
    },
};
</script>

<style lang="scss">
.generateOrderDialog {
    margin: 10px;

    .el-dialog {
        width: 100%;
        max-width: 700px;
    }

    .el-form-item--small.el-form-item {
        margin-bottom: 10px;
    }

    .el-dialog__body {
        padding: 10px 20px;
        padding-bottom: 20px;
    }
}
</style>